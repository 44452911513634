<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerAddress from '/~/components/drawer/views/personal-details/components/drawer-address.vue'
import { useAddresses } from '/~/composables/addresses'

const router = useRouter()
const { addresses, getAddresses, addressesLoaded } = useAddresses()

getAddresses()

const sortedAddresses = computed(() =>
  [...addresses.value].sort((item) => (item?.default ? -1 : 1))
)

function onAddAddress() {
  router.push({ name: 'addresses-add' })
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    title="Addresses"
    :action="onAddAddress"
    no-padding
  >
    <div class="px-5">
      <base-loader v-if="!addressesLoaded" fullwidth class="mt-5" />
      <div v-else-if="!addresses.length" class="space-y-3 text-center">
        <base-image src="address-illustration.svg" />
        <div class="text-xl font-bold">No address stored</div>
        <div>Add your address for a speedy checkout process.</div>
      </div>
      <template v-else>
        <div v-for="address of sortedAddresses" :key="address.id">
          <drawer-address :address="address" class="mb-3" />
        </div>
      </template>
    </div>
  </base-aside-page>
</template>
